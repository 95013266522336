@import 'styles/_colors.scss';
@import 'styles/_fonts.scss';


.homepage-container{
    display: flex;
    flex-direction: column;
    padding: 20px;
    justify-content: center;
}

.homepage-title{
    color: $primary-color;
    font-size: 64px;
    font-weight: bold;
    padding-top: 30px;
}

.homepage-subtitle{
    color: $secondary-color;
    font-size: 36px;
    font-weight: bold;
}