@import 'styles/_colors.scss';

.header-container{
    width: 100%;

    display: flex;
    align-items: center;

    padding: 10px 30px;
    border-bottom: 1px solid $border-color;
    gap: 20px;
}

.kompri-logo{
    height: 32px;
}